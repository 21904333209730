.paymentForm {
  background: transparent;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.payment-heading {
  color: #fff;
}

.paymentForm h2 {
  color: #333;
  font-size: 24px;
  margin-bottom: 12px;
}

.paymentForm input,
.StripeElement {
  height: 40px;
  padding: 10px 12px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  width: 100%; /* Full width */
  background-color: #fff;
}

.paymentForm button {
  background-color: #4b7bec;
  color: white;
  padding: 10px 15px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
}

.paymentForm button:hover {
  background-color: #3867d6;
}

/* Specific styles for Stripe Elements */
.StripeElement {
  padding: 11px 15px;
  background-color: white;
  border: 1px solid #ccd0d5;
  border-radius: 4px;
  box-shadow: inset 0 1px 3px rgba(50, 50, 93, 0.1);
}

.installmentsBtn {
  width: '100%';
  padding: '8px 16px';
  color: 'white';
  font-weight: '600';
  border-radius: '0.5rem';
  outline: 'none';
  transition: 'background-color 0.2s ease-in-out';
  display: 'flex';
  align-items: 'cenetr';
  justify-content: 'center'
}

@media (min-width: 992px) {
  .installmentsBtnContainer {
    width: 100%;
    padding: 0 18px;
  }
}

@media screen and (max-width: 749px) {
  .paymentForm {
    padding: 0;
  }
  .installmentsBtnContainer {
    margin-top: 12px;
  }
}
