.invisible {
  opacity: 0;
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

main {
  /* background-image: url(assets/img/0001.jpeg); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  margin: 0;
  min-height: -webkit-fill-available;
  height: 100vh;
  padding: 0;
  overflow: hidden;
}

main>div {
  background-color: black;
  background-image: url('/public/pmf-highway.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  height: 100%;
  width: 100vw;
  opacity: 1;
  -webkit-transition: opacity 300ms linear;
  -o-transition: opacity 300ms linear;
  transition: opacity 300ms linear;
}

#bin {
  height: 200px;
  width: 200px;
  position: absolute;
  margin-top: 0;
  overflow: hidden;
  top: 45%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 0.4;
  --angle: 0deg;
  border: 3px solid;
  border-image: linear-gradient(var(--angle), #12c2e9, #c471ed, #f64f59) 1;
  animation: 5s rotate linear infinite;
}

@keyframes rotate {
  to {
    --angle: 360deg;
  }
}

@-webkit-keyframes rotate {
  to {
    --angle: 360deg;
  }
}

#innerBin {
  position: relative;
  height: 100%;
  width: 100%;
}

.zoom {
  transition: transform 5000ms, opacity 200ms;
  opacity: 1;
}

.zoom.in {
  -webkit-transform: translate(-50%, -50%) scale(1.5) rotate(360deg) !important;
  -ms-transform: translate(-50%, -50%) scale(1.5) rotate(360deg) !important;
  transform: translate(-50%, -50%) scale(1.5) rotate(360deg) !important;

  opacity: 1 !important;
  border: 6px solid !important;
  border-image: linear-gradient(var(--angle), #12c2e9, #c471ed, #f64f59) 1 !important;
}

#bg {
  transition: all 1500ms ease-in-out;
}

.fadeout {
  transition: opacity 1s;
  opacity: 0;
  transform: rotate(-360deg) scale(2);
}

.fade-out {
  opacity: 0;
  transition: opacity 1.5s ease-out;
}

.border-boxed {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.border-boxed *,
.border-boxed *:before,
.border-boxed *:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

.det {
  position: relative;
  width: 100%;
  outline: 0.1em solid #d9d9d9;
  background-color: #fff;
  box-sizing: border-box;
}

.det-icon {
  display: inline-block;
  width: 36%;
  padding: 1% 2%;
  box-sizing: border-box;
  height: 100%;
  min-height: 210px;
}

.oic {
  width: 100%;
  height: 100%;
  cursor: pointer;
  font-size: 0.8em;
  text-align: center;
  position: relative;
}

.oic-image {
  width: 100%;
  padding: 5% 0 0 0;
}

.custom {
  border: 2px dashed gray;
  background: #2e2e2e;
  color: white;
}

.drag-handle {
  display: inline;
}


.rotate {
  transform: rotate(90deg);
}

#truck {
  background-image: url('/public/tesla-truck-2.png');
  background-repeat: no-repeat;
  background-size: 100%;
  width: 300px;
  height: 200px;
  position: absolute;
  left: 40%;
  bottom: 50px;

}

@media screen and (max-width: 749px) {

  #truck {
    width: 200px;
    height: 150px;
    bottom: 50px;
    left: 25%;
  }

}

.fadeOut {
  opacity: 0;
}

.arrow {
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: rotate(180deg);
  cursor: pointer;
}

.arrow span {
  display: block;
  width: 4.5rem;
  height: 4.5rem;
  border-bottom: 7.5px solid white;
  border-right: 7.5px solid white;
  transform: rotate(45deg);
  margin: -10px;
  animation: animate 2s infinite;
  box-shadow: 2px 2px 2px grey;
}

.arrow span:nth-child(2) {
  animation-delay: -0.2s;
}

.arrow span:nth-child(3) {
  animation-delay: -0.4s;
}

@keyframes animate {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-20px, -20px);
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: rotate(45deg) translate(20px, 20px);
  }
}

@media screen and (max-width: 749px) {
  .arrow span {
    width: 2.5rem;
    height: 2.5rem;
  }
}