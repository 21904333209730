.vertical-timeline * {
    box-sizing: border-box
}

.vertical-timeline {
    width: 95%;
    max-width: 1170px;
    margin: 0 auto;
    position: relative;
    padding: 3em 0;
}

.vertical-timeline::after {
    content: '';
    display: table;
    clear: both
}

.vertical-timeline::before {
    content: '';
    position: absolute;
    top: 0;
    left: 18px;
    height: 100%;
    width: 4px;
    background: var(--line-color)
}

.vertical-timeline.vertical-timeline--one-column-right::before {
    right: 18px;
    left: unset
}

@media only screen and (min-width: 1170px) {
    .vertical-timeline.vertical-timeline--two-columns {
        width:90%
    }

    .vertical-timeline.vertical-timeline--two-columns:before {
        left: 50%;
        margin-left: -2px
    }
}

.vertical-timeline-element {
    position: relative;
    margin: 2em 0
}

.vertical-timeline-element>div {
    min-height: 1px
}

.vertical-timeline-element:after {
    content: "";
    display: table;
    clear: both
}

.vertical-timeline-element:first-child {
    margin-top: 0
}

@media screen and ( max-width: 749px ) {
    .vertical-timeline-element:first-child .vertical-timeline-element-date {
        display: none;
    }
}

.vertical-timeline-element:last-child {
    margin-bottom: 0
}

@media only screen and (min-width: 1170px) {
    .vertical-timeline-element {
        margin:4em 0
    }

    .vertical-timeline-element:first-child {
        margin-top: 0
    }

    .vertical-timeline-element:last-child {
        margin-bottom: 0
    }
}

.vertical-timeline-element-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    box-shadow: 0 0 0 4px #fff,inset 0 2px 0 rgba(0,0,0,.08),0 3px 0 4px rgba(0,0,0,.05)
}

.vertical-timeline--one-column-right .vertical-timeline-element-icon {
    right: 0;
    left: unset
}

.vertical-timeline-element-icon svg {
    display: block;
    width: 24px;
    height: 24px;
    position: relative;
    left: 50%;
    top: 50%;
    margin-left: -12px;
    margin-top: -12px
}

@media only screen and (min-width: 1170px) {
    .vertical-timeline--two-columns .vertical-timeline-element-icon {
        width:60px;
        height: 60px;
        left: 50%;
        margin-left: -30px
    }
}

.vertical-timeline-element-icon {
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden
}

.vertical-timeline--animate .vertical-timeline-element-icon.is-hidden {
    visibility: hidden
}

.vertical-timeline--animate .vertical-timeline-element-icon.bounce-in {
    visibility: visible;
    -webkit-animation: cd-bounce-1 .6s;
    -moz-animation: cd-bounce-1 .6s;
    animation: cd-bounce-1 .6s
}

@-webkit-keyframes cd-bounce-1 {
    0% {
        opacity: 0;
        -webkit-transform: scale(.5)
    }

    60% {
        opacity: 1;
        -webkit-transform: scale(1.2)
    }

    100% {
        -webkit-transform: scale(1)
    }
}

@-moz-keyframes cd-bounce-1 {
    0% {
        opacity: 0;
        -moz-transform: scale(.5)
    }

    60% {
        opacity: 1;
        -moz-transform: scale(1.2)
    }

    100% {
        -moz-transform: scale(1)
    }
}

@keyframes cd-bounce-1 {
    0% {
        opacity: 0;
        -webkit-transform: scale(.5);
        -moz-transform: scale(.5);
        -ms-transform: scale(.5);
        -o-transform: scale(.5);
        transform: scale(.5)
    }

    60% {
        opacity: 1;
        -webkit-transform: scale(1.2);
        -moz-transform: scale(1.2);
        -ms-transform: scale(1.2);
        -o-transform: scale(1.2);
        transform: scale(1.2)
    }

    100% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1)
    }
}

.vertical-timeline-element-content {
    position: relative;
    margin-left: 60px;
    background: #fff;
    border-radius: .25em;
    padding: 1em;
    box-shadow: 0 3px 0 #ddd
}

.vertical-timeline--one-column-right .vertical-timeline-element-content {
    margin-right: 60px;
    margin-left: unset
}

.vertical-timeline-element--no-children .vertical-timeline-element-content {
    background: 0 0;
    box-shadow: none
}

.vertical-timeline-element-content:after {
    content: "";
    display: table;
    clear: both
}

.vertical-timeline-element-content h2 {
    color: #303e49
}

.vertical-timeline-element-content .vertical-timeline-element-date,.vertical-timeline-element-content p {
    font-size: 13px;
    font-size: .8125rem;
    font-weight: 500
}

@media screen and ( max-width: 749px ) {
    .vertical-timeline-element-content .vertical-timeline-element-date,.vertical-timeline-element-content p {
        font-size: 1.4rem;
    }
}

.vertical-timeline-element-content .vertical-timeline-element-date {
    display: inline-block
}

.vertical-timeline-element-content p {
    margin: 1em 0 0;
    line-height: 1.6
}

@media screen and ( max-width: 749px ) {
    .vertical-timeline-element-content p {
        margin: .5em 0 1em;
    }
}

.vertical-timeline-element-title {
    margin: 1rem 0;
    transform: scale(1, 1.5);
    font-family: 'Source Sans 3';
    font-size: 1.85rem;
    text-transform: uppercase;
}

@media screen and ( max-width: 749px ) {
    .vertical-timeline-element-title {
        letter-spacing: 0.01rem;
        font-size: 1.55rem;
        line-height: 2rem;
    }
}


.vertical-timeline-element-subtitle {
    margin: 0
}

.vertical-timeline-element-content .vertical-timeline-element-date {
    float: left;
    padding: .8em 0;
    opacity: .7
}

.vertical-timeline-element-content-arrow {
    content: '';
    position: absolute;
    top: 16px;
    right: 100%;
    height: 0;
    width: 0;
    border: 7px solid transparent;
    border-right: 7px solid #fff
}

.vertical-timeline--one-column-right .vertical-timeline-element-content-arrow {
    content: '';
    position: absolute;
    top: 16px;
    right: unset;
    left: 100%;
    height: 0;
    width: 0;
    border: 7px solid transparent;
    border-left: 7px solid #fff
}

.vertical-timeline--one-column-right .vertical-timeline-element-content::before {
    left: 100%;
    border-left: 7px solid #fff;
    border-right: unset
}

.vertical-timeline-element--no-children .vertical-timeline-element-content::before {
    display: none
}

.vertical-timeline-element--no-children .vertical-timeline-element-content-arrow {
    display: none
}

@media only screen and (min-width: 768px) {
    .vertical-timeline-element-title {

    }

    .vertical-timeline-element-content h2 {
        font-size:20px;
        font-size: 1.55rem
    }

    .vertical-timeline-element-content p {
        font-size: 16px;
        font-size: 1.55rem
    }

    .vertical-timeline-element-content .vertical-timeline-element-date {
        font-size: 14px;
        font-size: .875rem
    }
}

@media only screen and (min-width: 1170px) {
    .vertical-timeline--two-columns .vertical-timeline-element-content {
        margin-left:0;
        padding: 1.5em;
        width: 44%
    }

    .vertical-timeline--two-columns .vertical-timeline-element-content-arrow {
        top: 24px;
        left: 100%;
        transform: rotate(180deg)
    }

    .vertical-timeline--two-columns .vertical-timeline-element-content .vertical-timeline-element-date {
        position: absolute;
        width: 100%;
        left: 124%;
        top: 6px;
        font-size: 2rem
    }

    .vertical-timeline--two-columns .vertical-timeline-element.vertical-timeline-element--right .vertical-timeline-element-content,.vertical-timeline--two-columns .vertical-timeline-element:nth-child(even):not(.vertical-timeline-element--left) .vertical-timeline-element-content {
        float: right
    }

    .vertical-timeline--two-columns .vertical-timeline-element.vertical-timeline-element--right .vertical-timeline-element-content-arrow,.vertical-timeline--two-columns .vertical-timeline-element:nth-child(even):not(.vertical-timeline-element--left) .vertical-timeline-element-content-arrow {
        top: 24px;
        left: auto;
        right: 100%;
        transform: rotate(0)
    }

    .vertical-timeline--one-column-right .vertical-timeline-element.vertical-timeline-element--right .vertical-timeline-element-content-arrow,.vertical-timeline--one-column-right .vertical-timeline-element:nth-child(even):not(.vertical-timeline-element--left) .vertical-timeline-element-content-arrow {
        top: 24px;
        left: 100%;
        right: auto;
        transform: rotate(0)
    }

    .vertical-timeline--two-columns .vertical-timeline-element.vertical-timeline-element--right .vertical-timeline-element-content .vertical-timeline-element-date,.vertical-timeline--two-columns .vertical-timeline-element:nth-child(even):not(.vertical-timeline-element--left) .vertical-timeline-element-content .vertical-timeline-element-date {
        left: auto;
        right: 124%;
        text-align: right
    }
}

.vertical-timeline--animate .vertical-timeline-element-content.is-hidden {
    visibility: hidden
}

.vertical-timeline--animate .vertical-timeline-element-content.bounce-in {
    visibility: visible;
    -webkit-animation: cd-bounce-2 .6s;
    -moz-animation: cd-bounce-2 .6s;
    animation: cd-bounce-2 .6s
}

@media only screen and (min-width: 1170px) {
    .vertical-timeline--two-columns.vertical-timeline--animate .vertical-timeline-element.vertical-timeline-element--right .vertical-timeline-element-content.bounce-in,.vertical-timeline--two-columns.vertical-timeline--animate .vertical-timeline-element:nth-child(even):not(.vertical-timeline-element--left) .vertical-timeline-element-content.bounce-in {
        -webkit-animation:cd-bounce-2-inverse .6s;
        -moz-animation: cd-bounce-2-inverse .6s;
        animation: cd-bounce-2-inverse .6s
    }
}

@media only screen and (max-width: 1169px) {
    .vertical-timeline--animate .vertical-timeline-element-content.bounce-in {
        visibility:visible;
        -webkit-animation: cd-bounce-2-inverse .6s;
        -moz-animation: cd-bounce-2-inverse .6s;
        animation: cd-bounce-2-inverse .6s
    }
}

@-webkit-keyframes cd-bounce-2 {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-100px)
    }

    60% {
        opacity: 1;
        -webkit-transform: translateX(20px)
    }

    100% {
        -webkit-transform: translateX(0)
    }
}

@-moz-keyframes cd-bounce-2 {
    0% {
        opacity: 0;
        -moz-transform: translateX(-100px)
    }

    60% {
        opacity: 1;
        -moz-transform: translateX(20px)
    }

    100% {
        -moz-transform: translateX(0)
    }
}

@keyframes cd-bounce-2 {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-100px);
        -moz-transform: translateX(-100px);
        -ms-transform: translateX(-100px);
        -o-transform: translateX(-100px);
        transform: translateX(-100px)
    }

    60% {
        opacity: 1;
        -webkit-transform: translateX(20px);
        -moz-transform: translateX(20px);
        -ms-transform: translateX(20px);
        -o-transform: translateX(20px);
        transform: translateX(20px)
    }

    100% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0)
    }
}

@-webkit-keyframes cd-bounce-2-inverse {
    0% {
        opacity: 0;
        -webkit-transform: translateX(100px)
    }

    60% {
        opacity: 1;
        -webkit-transform: translateX(-20px)
    }

    100% {
        -webkit-transform: translateX(0)
    }
}

@-moz-keyframes cd-bounce-2-inverse {
    0% {
        opacity: 0;
        -moz-transform: translateX(100px)
    }

    60% {
        opacity: 1;
        -moz-transform: translateX(-20px)
    }

    100% {
        -moz-transform: translateX(0)
    }
}

@keyframes cd-bounce-2-inverse {
    0% {
        opacity: 0;
        -webkit-transform: translateX(100px);
        -moz-transform: translateX(100px);
        -ms-transform: translateX(100px);
        -o-transform: translateX(100px);
        transform: translateX(100px)
    }

    60% {
        opacity: 1;
        -webkit-transform: translateX(-20px);
        -moz-transform: translateX(-20px);
        -ms-transform: translateX(-20px);
        -o-transform: translateX(-20px);
        transform: translateX(-20px)
    }

    100% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0)
    }
}


.RoadMap {
    position: relative;
    flex-direction: row;
    height: 100vh;
    width: 100vw;
    overflow: scroll;
    scroll-snap-type: x mandatory;
    max-width: 100vw;
    display: flex;
    z-index: 9;
}

@media screen and ( max-width: 749px ) {

    .RoadMap {
        height: -webkit-fill-available;
        padding-bottom: 3.5rem;
    }
}

.Base.breakdown::after {
    z-index: 0;
    pointer-events: none;
    display: block;
    content: " huh";
    background-color: rgba(0,0,0,0.5);
    background-image: url(../../public/tripp3.jpg);
    animation: 5s OUTnIN infinite alternate;
    position: absolute;
    height: 100vh;
    width: 100vw;
    z-index: 6;
    top: 0;
}

@keyframes OUTnIN {
  0% { opacity: 0; }
  100%   { opacity: 0.75; }
}

.glow-on-hover {
    position: relative;
    padding: 1rem 2rem;
    text-align: center;
    background-color: transparent!important;
    background: transparent!important;
    /*background-image: linear-gradient(to right, #3898FF, #7A70FF);*/
}

.glow-on-hover:after {
    position: relative;
    padding: 1rem 2rem;
    text-align: center;
    background-color: transparent!important;
    background: transparent!important;
    /*background-image: linear-gradient(to right, #3898FF, #7A70FF);*/
}

/* Timeline - Years */
.timelines-years {
    position: relative;
    top: 300px;
    padding: 0 0 0 200px;
    margin: 0;
    white-space: nowrap;
    border-top: 1px solid #282828;
    list-style: none;
    font-size: 0;
    z-index: 2;
}

.timelines-years li {
  position: relative;
  top: -6px;
  display: inline-block;
  width: 400px;
  color: #868686;
  font-size: 1.65rem;
  line-height: 1.85rem;
  text-indent: -12px;
}

.timelines-years li:last-child {
  width: 100px;
}

.timeline-events {
  position: absolute;
  top: 170px;
  padding: 0;
  list-style: none;
  white-space: nowrap;
  font-size: 0;
  z-index: 2;
}

.timeline-events h2,
.timeline-events h3,
.timeline-events h4 {
  margin: 0 0 1px 0;
  font-weight: normal;
  font-size: 1.65rem;
}

.timeline-events h2 {
  color: #777;
  text-transform: uppercase;
}

.timeline-events h4 {
  color: #fff;
  font-style: italic;
}

.timeline-events li {
  position: relative;
  display: inline-block;
}

.timeline-events li:before {
  position: absolute;
  left: 0;
  bottom: -36px;
  height: 8px;
  border-radius: 8px;
  content: '';
}

.timeline-events li:nth-child(1) {
  bottom: 0px;
  width: 30rem;
}

.timeline-events li:nth-child(1):before {
    width: 30rem;
}

.timeline-events li:nth-child(2) {
  bottom: 16px;
}

.timeline-events li:nth-child(3) {
  bottom: 32px;
}

.timeline-events li:nth-child(4) {
  bottom: 48px;
}

.timeline-events li:nth-child(5) {
  bottom: 64px;
}

.timeline-events li:nth-child(1) h3 {
  color: #C2E34E;
}

/* Orange */
.timeline-events li:nth-child(2):before {
  background: #FF9704;
}

.timeline-events li:nth-child(2) h3 {
  color: #FF9704;
}

/* Blue */
.timeline-events li:nth-child(3):before {
  background: #56C2F3;
}

.timeline-events li:nth-child(3) h3 {
  color: #56C2F3;
}

/* Red */
.timeline-events li:nth-child(4):before {
  background: #DD3D01;
}

.timeline-events li:nth-child(4) h3 {
  color: #DD3D01;
}

/* Aqua/Grey */
.timeline-events li:nth-child(5):before {
  background: #4A8B8F;
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuMCIgeTE9IjAuNSIgeDI9IjEuMCIgeTI9IjAuNSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzRhOGI4ZiIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzBmMGYwZiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
  background-size: 100%;
  background-image: -webkit-gradient(linear, 0% 50%, 100% 50%, color-stop(0%, #4a8b8f), color-stop(100%, #0f0f0f));
  background-image: -moz-linear-gradient(left, #4a8b8f 0%, #0f0f0f 100%);
  background-image: -webkit-linear-gradient(left, #4a8b8f 0%, #0f0f0f 100%);
  background-image: linear-gradient(to right, #4a8b8f 0%, #0f0f0f 100%);
  border-radius: 6px 0 0 6px;
}

.timeline-events li:nth-child(5) h3 {
  color: #4A8B8F;
}

/* Timeline - Events - Grid */
.timeline-event-years-0,
.timeline-event-years-0:before {
  width: 0px;
}

.timeline-event-years-0-5,
.timeline-event-years-0-5:before {
  width: 250px;
}

.timeline-event-years-1,
.timeline-event-years-1:before {
  width: 300px;
}

.timeline-event-years-1-5,
.timeline-event-years-1-5:before {
  width: 350px;
}

.timeline-event-years-2,
.timeline-event-years-2:before {
  width: 400px;
}

.timeline-event-years-2-5,
.timeline-event-years-2-5:before {
  width: 450px;
}

.timeline-event-years-3,
.timeline-event-years-3:before {
  width: 500px;
}

.timeline-event-years-3-5,
.timeline-event-years-3-5:before {
  width: 550px;
}

.timeline-event-years-4,
.timeline-event-years-4:before {
  width: 60rem;
}

.timeline-event-years-4-5,
.timeline-event-years-4-5:before {
  width: 65rem;
}

.timeline-event-years-5,
.timeline-event-years-5:before {
  width: 70rem;
}

.timeline-event-years-5-5,
.timeline-event-years-5-5:before {
  width: 75rem;
}

.timeline-event-years-6,
.timeline-event-years-6:before {
  width: 80rem;
}

.timeline-event-years-6-5,
.timeline-event-years-6-5:before {
  width: 85rem;
}

.timeline-event-years-7,
.timeline-event-years-7:before {
  width: 90rem;
}

.timeline-event-years-7-5,
.timeline-event-years-7-5:before {
  width: 95rem;
}

.timeline-event-years-8,
.timeline-event-years-8:before {
  width: 100rem;
}

.timeline-event-years-8-5,
.timeline-event-years-8-5:before {
  width: 100rem;
}

/* Timeline - Legend */
.timeline-event-legend {
  position: relative;
}

.timeline-event-legend span {
  position: absolute;
  bottom: -150px;
  left: 850px;
}

.timeline-event-legend h2,
.timeline-event-legend h3,
.timeline-event-legend h4 {
  display: inline;
  margin-right: 10px;
}

.timeline-event-legend i {
  position: absolute;
  width: 0;
  height: 0;
  border: 4px solid transparent;
  border-bottom-color: #fff;
}

.timeline-event-legend i:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border: 4px solid transparent;
  border-top-color: #fff;
}

.timeline-event-legend > i {
  bottom: -32px;
  right: -12px;
}

.timeline-event-legend > i:after {
  left: -4px;
  bottom: -12px;
}

.timeline-event-legend span > i {
  top: -1px;
  left: -14px;
}

.timeline-event-legend span > i:after {
  left: -4px;
  bottom: -12px;
}


.fixed.timeline-event-legend {
    position:fixed;
    bottom: 25rem;
    font-size: 1.15rem;
    z-index: 9;
    font-weight: bold;
    left: 10vw;
    width: 80vw;
}

.vertical-timeline {
    height: fit-content;
    padding-bottom: 5rem;
}

@media screen and ( min-width: 750px ) {
    .vertical-timeline {
        margin-top: 10rem;
        padding-bottom: 0;
    }
}

.vertical-timeline-element-icon {
    box-shadow: -21px -25px 97px 10px #5acee3, 3px 2px 77px 10px #f30bf5!important;
}

@media screen and ( max-width: 1170px ) {
    .vertical-timeline-element-icon {
        left: 10px!important;
        width: 20px!important;
        height: 20px!important;
    }
}

@media screen and ( min-width: 1169px ) {
    .vertical-timeline-element-icon {
        left: calc(50% + 20px)!important;
        width: 20px!important;
        height: 20px!important;
    }
}

.vertical-timeline-element-content.bounce-in {
    background-image: linear-gradient(to right, #3898FF, #7A70FF);
}

.vertical-timeline-element-content .vertical-timeline-element-date {
    float: left;
    padding-top: 0!important;
    opacity: 1;
    font-size: 2rem;
    display: block;
    transform: scale(1, 1.5);
    margin: 0;
    padding: 0.5rem 2rem 0rem;
    background-color: #4ca720;
    border: 4px solid white;
    border-radius: 5px;
    max-width: fit-content;
    font-family: 'Source Sans 3';
    text-transform: uppercase;
    font-weight: bold;
    text-shadow: 1px 1px 2px black;
    letter-spacing: -0.05rem;
    white-space: nowrap;
    text-align: center;
}

@media screen and ( max-width: 749px ) {

    .vertical-timeline-element-content .vertical-timeline-element-date {
        margin: 4rem 0 1rem;
    }
}

@media only screen and (min-width: 1170px) {

    .vertical-timeline--two-columns .vertical-timeline-element-content .vertical-timeline-element-date {
        left: 119%;
        top: 0px;
    }
}

@media only screen and (min-width: 1170px) {

    .vertical-timeline.vertical-timeline--two-columns {
        padding-top: 20rem;
        padding-bottom: 20rem;
    }

    .vertical-timeline.vertical-timeline--two-columns:before {
        left: 50%;
        margin-left: -2px;
    }
}

.vertical-timeline--animate .vertical-timeline-element-content.bounce-in {
    visibility: visible;
    -webkit-animation: fadeIn 1.6s!important;
    animation: fadeIn 1.6s!important;
}

.vertical-timeline-element:first-child {
    margin: 10rem 0 8rem!important;
}

.vertical-timeline-element {
    margin: 8rem 0!important;
}

@media screen and ( max-width: 749px ) {

    .vertical-timeline-element:first-child {
        margin: 10rem 0 4rem!important;
    }

    .vertical-timeline-element {
        margin: 4rem 0!important;
    }
}