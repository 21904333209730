/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

@import url("https://cdn.jsdelivr.net/gh/thecoolagency/thecoolcdn@v1.2.1/css/fix.tca.min.css");
@import url("https://cdn.jsdelivr.net/gh/thecoolagency/thecoolcdn@v1.2.1/css/animations.tca.min.css");

/* FONTS */

@font-face {
  font-family: "Source Sans 3";
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
  src: url("../assets/fonts/source-sans/WOFF2/TTF/SourceSans3-ExtraLight.ttf.woff2")
      format("woff2"),
    url("../assets/fonts/source-sans/WOFF/OTF/SourceSans3-ExtraLight.otf.woff")
      format("woff");
}

/* Dooooooo */

@font-face {
  font-family: "Source Sans 3";
  font-weight: 200;
  font-style: italic;
  font-stretch: normal;
  src: url("../assets/fonts/source-sans/WOFF2/TTF/SourceSans3-ExtraLightIt.ttf.woff2")
      format("woff2"),
    url("../assets/fonts/source-sans/WOFF/OTF/SourceSans3-ExtraLightIt.otf.woff")
      format("woff");
}

@font-face {
  font-family: "Source Sans 3";
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  src: url("../assets/fonts/source-sans/WOFF2/TTF/SourceSans3-Light.ttf.woff2")
      format("woff2"),
    url("../assets/fonts/source-sans/WOFF/OTF/SourceSans3-Light.otf.woff")
      format("woff");
}

@font-face {
  font-family: "Source Sans 3";
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
  src: url("../assets/fonts/source-sans/WOFF2/TTF/SourceSans3-LightIt.ttf.woff2")
      format("woff2"),
    url("../assets/fonts/source-sans/WOFF/OTF/SourceSans3-LightIt.otf.woff")
      format("woff");
}

@font-face {
  font-family: "Source Sans 3";
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  src: url("../assets/fonts/source-sans/WOFF2/TTF/SourceSans3-Regular.ttf.woff2")
      format("woff2"),
    url("../assets/fonts/source-sans/WOFF/OTF/SourceSans3-Regular.otf.woff")
      format("woff");
}

@font-face {
  font-family: "Source Sans 3";
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
  src: url("../assets/fonts/source-sans/WOFF2/TTF/SourceSans3-It.ttf.woff2")
      format("woff2"),
    url("../assets/fonts/source-sans/WOFF/OTF/SourceSans3-It.otf.woff")
      format("woff");
}

@font-face {
  font-family: "Source Sans 3";
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  src: url("../assets/fonts/source-sans/WOFF2/TTF/SourceSans3-Semibold.ttf.woff2")
      format("woff2"),
    url("../assets/fonts/source-sans/WOFF/OTF/SourceSans3-Semibold.otf.woff")
      format("woff");
}

@font-face {
  font-family: "Source Sans 3";
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
  src: url("../assets/fonts/source-sans/WOFF2/TTF/SourceSans3-SemiboldIt.ttf.woff2")
      format("woff2"),
    url("../assets/fonts/source-sans/WOFF/OTF/SourceSans3-SemiboldIt.otf.woff")
      format("woff");
}

@font-face {
  font-family: "Source Sans 3";
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  src: url("../assets/fonts/source-sans/WOFF2/TTF/SourceSans3-Bold.ttf.woff2")
      format("woff2"),
    url("../assets/fonts/source-sans/WOFF/OTF/SourceSans3-Bold.otf.woff")
      format("woff");
}

@font-face {
  font-family: "Source Sans 3";
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
  src: url("../assets/fonts/source-sans/WOFF2/TTF/SourceSans3-BoldIt.ttf.woff2")
      format("woff2"),
    url("../assets/fonts/source-sans/WOFF/OTF/SourceSans3-BoldIt.otf.woff")
      format("woff");
}

@font-face {
  font-family: "Source Sans 3";
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  src: url("../assets/fonts/source-sans/WOFF2/TTF/SourceSans3-Black.ttf.woff2")
      format("woff2"),
    url("../assets/fonts/source-sans/WOFF/OTF/SourceSans3-Black.otf.woff")
      format("woff");
}

@font-face {
  font-family: "Source Sans 3";
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
  src: url("../assets/fonts/source-sans/WOFF2/TTF/SourceSans3-BlackIt.ttf.woff2")
      format("woff2"),
    url("../assets/fonts/source-sans/WOFF/OTF/SourceSans3-BlackIt.otf.woff")
      format("woff");
}

@font-face {
  font-family: "Source Sans 3 VF";
  font-weight: 200 900;
  font-style: normal;
  font-stretch: normal;
  src: url("../assets/fonts/source-sans/WOFF2/VAR/SourceSans3VF-Roman.ttf.woff2")
      format("woff2"),
    url("../assets/fonts/source-sans/WOFF/VAR/SourceSans3VF-Roman.ttf.woff")
      format("woff"),
    url("../assets/fonts/source-sans/VAR/SourceSans3VF-Roman.ttf")
      format("truetype");
}

@font-face {
  font-family: "Source Sans 3 VF";
  font-weight: 200 900;
  font-style: italic;
  font-stretch: normal;
  src: url("../assets/fonts/source-sans/WOFF2/VAR/SourceSans3VF-Italic.ttf.woff2")
      format("woff2"),
    url("../assets/fonts/source-sans/WOFF/VAR/SourceSans3VF-Italic.ttf.woff")
      format("woff"),
    url("../assets/fonts/source-sans/VAR/SourceSans3VF-Italic.ttf")
      format("truetype");
}

/* FIX */

*::-webkit-scrollbar {
  display: none;
}
*::-webkit-scrollbar-button {
  display: none;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.25);
  border: 1px solid rgba(0, 0, 0, 0.45);
}
*::-webkit-scrollbar-track-piece {
  display: none;
  background-color: rgba(0, 0, 0, 0.15);
}
*::-webkit-scrollbar-thumb {
  display: none;
  background: rgba(0, 0, 0, 0.45);
}
*::-webkit-resizer {
  display: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  color: white;
}

a {
  color: #fff;
  text-decoration: none;
  font-family: inherit;
}

p {
  color: white;
  line-height: 2.2rem;
  text-shadow: 1px 1px 2px black;
  font-weight: 600;
}

h1 {
  color: white;
  text-shadow: 1px 1px 2px black;
}

h3 {
  text-shadow: 1px 1px 2px black;
  font-weight: 700;
  font-family: "Source Sans 3";
}

div {
  color: white;
  font-family: inherit;
}

.title {
  right: 0;
  position: absolute;
  display: block;
  min-width: 100vw;
  -webkit-transform: scale(1, 1.5);
  -ms-transform: scale(1, 1.5);
  transform: scale(1, 1.5);
  margin: 0;
  top: 8vh;
  font-family: "Source Sans 3";
  font-size: 7vw;
  text-transform: uppercase;
  letter-spacing: -0.05rem;
  white-space: nowrap;
  text-align: center;
}

@media screen and (min-width: 750px) {
  .title {
    top: 12.5%;
    -webkit-transform: scale(1, 1.5) translateY(-50%);
    -ms-transform: scale(1, 1.5) translateY(-50%);
    transform: scale(1, 1.5) translateY(-50%);
    font-size: 6vw;
  }
}

@media screen and (min-width: 1300px) {
  .title {
    top: 14.5%;
    -webkit-transform: scale(1, 1.5) translateY(-50%);
    -ms-transform: scale(1, 1.5) translateY(-50%);
    transform: scale(1, 1.5) translateY(-50%);
    font-size: 6rem;
  }
}

.responsive {
  width: 100%;
  height: auto;
  border-radius: 12px;
}

.link,
a.link-to {
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.2rem;
  font-size: 2.2rem;
  text-shadow: 1px 1px 2px black;
  -webkit-transform: scale(1, 1.5);
  -ms-transform: scale(1, 1.5);
  transform: scale(1, 1.5);
  font-family: "Source Sans 3";
  text-transform: uppercase;
  letter-spacing: -0.05rem;
  white-space: nowrap;
}

a:visited,
a:focus {
  color: rgba(255, 255, 255, 0.75);
  text-decoration: none;
}

a:hover {
  color: rgba(255, 255, 255, 0.75);
  text-decoration: none;
}

button[disabled] {
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.35);
  -webkit-transform: scale(1.01);
  -ms-transform: scale(1.01);
  transform: scale(1.01);
}

#root {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  max-height: 100vh;
}

#Stars circle {
  fill: #ffffff;
}

/* WALLET */

.top-connect {
  position: absolute;
  z-index: 13;
  right: 1rem;
  top: 1.4rem;
}

@media screen and (min-width: 749px) {
  .top-connect {
    right: 1rem;
    top: 1.5rem;
  }
}

/* NAVIGATION */

.Navigation {
  position: absolute;
  z-index: 10;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  bottom: 2rem;
}

@media screen and (min-width: 749px) {
  .Navigation {
    bottom: unset;
  }
}

.Navigation ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  list-style-type: none;
  padding: 0;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-column-gap: 2rem;
  -moz-column-gap: 2rem;
  column-gap: 2rem;
}

/* DECOR SVG */

.thisStars {
  position: absolute;
}

#root .wrap .thisStars #Stars {
  z-index: 0;
  position: absolute;
  -webkit-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
  -webkit-transition: opacity 900ms ease-in-out,
    -webkit-transform 900ms ease-in-out;
  transition: opacity 900ms ease-in-out, -webkit-transform 900ms ease-in-out;
  -o-transition: opacity 900ms ease-in-out, transform 900ms ease-in-out;
  transition: opacity 900ms ease-in-out, transform 900ms ease-in-out;
  transition: opacity 900ms ease-in-out, transform 900ms ease-in-out,
    -webkit-transform 900ms ease-in-out;
  bottom: unset;
  top: 0;
  width: auto;
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

@media (orientation: landscape) {
  #root .wrap .thisStars #Stars {
    min-width: 100vw;
  }
}

@media (orientation: portrait) {
  #root .wrap .thisStars #Stars {
    min-height: 100vh;
    height: 100vh;
  }
}

#root .wrap .thisMoon #Moon,
#root .wrap .thisMoon #Sun {
  max-width: 120px;
  z-index: 9;
  position: absolute;
  bottom: unset;
  top: 100px;
  left: 100px;
}

@media screen and (min-width: 749px) {
  #root .wrap .thisMoon #Moon,
  #root .wrap .thisMoon #Sun {
    max-width: 160px;
    top: 0px;
  }
}

.Planets {
  position: absolute;
  z-index: 1;
}

.Planets > * {
  position: absolute;
}

@media screen and (max-width: 749px) {
  .Planets > * {
    width: 100px;
  }
}

/* Content */

.Base .viewport {
  position: relative;
  height: 100vh;
  position: relative;
  z-index: 4;
}

/* BOTTOM svg elements */

.Map {
  position: absolute;
  min-width: 100vw;
  width: auto;
  height: auto;
  min-height: 100vh;
  position: relative;
  z-index: 9;
}

.BG {
  bottom: 0;
  left: 0;
  right: -10vw;
}

.Base .Bottom {
  -webkit-transition: top 900ms ease-in-out, opacity 900ms ease-in-out;
  -o-transition: top 900ms ease-in-out, opacity 900ms ease-in-out;
  transition: top 900ms ease-in-out, opacity 900ms ease-in-out;
}

.Base .Bottom .BG {
  position: absolute;
}

.Founders {
  position: absolute;
  max-width: 105vw;
  top: -100vh;
  z-index: 0;
}

.Vision {
  z-index: -1;
  position: absolute;
  max-width: 2000px;
  width: 100%;
  left: 50%;
  bottom: -10vh;
  -webkit-transform: translate(-50%, 10px);
  -ms-transform: translate(-50%, 10px);
  transform: translate(-50%, 10px);
}

.main {
  z-index: 1;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.EnterPage .main,
.EnterPage .BGAlt,
.EnterPage .BG {
  display: none;
}

@media screen and (max-width: 500px) {
  .Founders {
    max-width: 100%;
    top: -68vh;
  }

  .founders {
    -webkit-transform: scale(1.6) !important;
    -ms-transform: scale(1.6) !important;
    transform: scale(1.6) !important;
    bottom: 5vh !important;
    position: relative;
  }

  .Vision {
    width: 220vw;
    margin-left: unset;
    margin-right: unset;
    bottom: 0;
  }
}

.thisSun {
  position: relative;
}

.thisSun,
.Base .Bottom {
  -webkit-transition: -webkit-transform 1000ms linear;
  transition: -webkit-transform 1000ms linear;
  -o-transition: transform 1000ms linear;
  transition: transform 1000ms linear;
  transition: transform 1000ms linear, -webkit-transform 1000ms linear;
}

.Base .Bottom {
  position: absolute;
  top: 0;
  height: 100vh;
}

.Base.minting .Bottom {
  -webkit-transform: scale(0.2);
  -ms-transform: scale(0.2);
  transform: scale(0.2);
  -webkit-transition: -webkit-transform 1000ms linear;
  transition: -webkit-transform 1000ms linear;
  -o-transition: transform 1000ms linear;
  transition: transform 1000ms linear;
  transition: transform 1000ms linear, -webkit-transform 1000ms linear;
}

.thisSun.minting {
  -webkit-transform: scale(3.5);
  -ms-transform: scale(3.5);
  transform: scale(3.5);
}

/* CONTENT */

.bg {
  position: absolute;
  height: 100vh;
  width: 100vw;
  background-size: auto 100vh;
  background-position: center;
}

.Home .bg,
.AboutWrapper .bg,
.RoadmapWrapper .bg,
.FoundersWrapper .bg,
.VisionWrapper .bg {
  background-image: url("../../public/BG.png");
}

.HomeWrapper .viewport .container {
  max-width: 80rem;
}

.MintWrapper .viewport .container {
  max-width: 80rem;
}

.Base.Home .container {
  z-index: 9;
}

.HomeWrapper,
.MintWrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  overflow: hidden;
}

.RoadmapWrapper .Base,
.MintWrapper .Base,
.AboutWrapper .Base,
.VisionWrapper .Base {
  position: absolute;
  z-index: 9;
  height: 100vh;
  top: 0;
  width: 100vw;
  overflow: hidden;
}

.HomeWrapper .Base {
  overflow: hidden;
}

.RouteWrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  max-height: 100vh;
}

@media screen and (max-width: 749px) {
  .RouteWrapper.HomeWrapper {
    max-height: -webkit-fill-available;
    overflow: scroll;
  }

  .TokenomicsWrapper .container {
    overflow: scroll;
    height: -webkit-fill-available;
    max-height: 70vh;
  }
}

.TopContainer {
  position: absolute;
  top: 10;
}

.FoundersWrapper .container {
  overflow-y: visible;
  overflow-x: visible;
}

@keyframes bump {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-15px);
  }
  60% {
    transform: translateY(-7px);
  }
}

.bumping {
  animation: bump 2s infinite;
}

/* Desktop */

@media screen and (min-width: 750px) {
  .FoundersCards {
    position: relative;
    opacity: 1;
    right: 0;
    -webkit-animation-duration: 6s;
    animation-duration: 6s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-name: likeJesus2;
    animation-name: likeJesus2;
  }

  .FoundersCards > div {
    border-top: 0;
    margin-top: 1rem;
    z-index: 3;
    border-radius: 150px;
    position: relative;
    opacity: 1;
    right: 0;
    border-radius: 100px;
    -webkit-filter: drop-shadow(10px 10px 200px rgb(255, 0, 255, 0.4))
      drop-shadow(-10px -10px 50px rgb(255, 0, 255, 0.4));
    filter: drop-shadow(10px 10px 200px rgb(255, 0, 255, 0.4))
      drop-shadow(-10px -10px 50px rgb(255, 0, 255, 0.4));
  }

  .FoundersCards > div:after,
  .FoundersCards > div:before {
    width: 2px;
    height: 5px;
    top: calc(100% + 1px);
    left: 50%;
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    background-color: #fff;
  }

  .FoundersCards > div:after {
    width: 250px;
    height: 250px;
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 100%;
    z-index: 1;
    -webkit-box-shadow: 10px -55px 30px 15px #823ca6,
      24px -10px 47px 10px #aab3d2, -21px -25px 97px 10px #5acee3,
      51px 5px 17px 10px #1b7d8f, 3px 2px 77px 10px #f30bf5;
    box-shadow: 10px -55px 30px 15px #823ca6, 24px -10px 47px 10px #aab3d2,
      -21px -25px 97px 10px #5acee3, 51px 5px 17px 10px #1b7d8f,
      3px 2px 77px 10px #f30bf5;
    -webkit-animation: shadow-rotate 1.5s linear infinite;
    animation: shadow-rotate 1.5s linear infinite;
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    top: calc(100% + 1px);
    left: 50%;
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    background-color: #fff;

    border: 2px solid;
    width: 8px;
    height: 18px;
    left: 50%;
    top: -10px;
    border-radius: 4px;
    transform: translate(-50%, 0);
  }

  .FoundersCards > div::before,
  .FoundersCards > div::after {
    content: "";
    display: block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: absolute;
    background: transparent;
    border: 0;
    top: 50%;
    zoom: 3;
    height: 0;
    width: 0;
    z-index: -1;
  }

  .FoundersCards > *:first-child > div > div img {
    -webkit-animation: float 6s ease-in-out infinite;
    animation: float 6s ease-in-out infinite;
  }

  .FoundersCards > *:last-child > div > div img {
    -webkit-animation: float 6s ease-in-out infinite 3s;
    animation: float 6s ease-in-out infinite 3s;
  }

  #root
    > div
    > div
    > div.RouteWrapper.FoundersWrapper
    > div.Base
    > div.viewport
    > div.container {
    max-height: -webkit-fit-content;
    max-height: -moz-fit-content;
    max-height: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }

  .MintCards {
    position: relative;
    opacity: 1;
    right: 0;
    -webkit-animation-duration: 6s;
    animation-duration: 6s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-name: likeJesus2;
    animation-name: likeJesus2;
  }MintCards > div {
    border-top: 0;
    z-index: 3;
    border-radius: 150px;
    position: relative;
    opacity: 1;
    right: 0;
    border-radius: 100px;
  }

  .MintCards > div:after,
  .MintCards > div:before {
    width: 2px;
    height: 5px;
    top: calc(100% + 1px);
    left: 50%;
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    background-color: #fff;
  }

  .MintCards > div:after {
    width: 250px;
    height: 250px;
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 100%;
    z-index: 1;
    -webkit-animation: shadow-rotate 1.5s linear infinite;
    animation: shadow-rotate 1.5s linear infinite;
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    top: calc(100% + 1px);
    left: 50%;
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    background-color: #fff;
    border: 2px solid;
    width: 8px;
    height: 18px;
    left: 50%;
    top: -10px;
    border-radius: 4px;
    transform: translate(-50%, 0);
  }

  .MintCards > div::before,
  .MintCards > div::after {
    content: "";
    display: block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: absolute;
    background: transparent;
    border: 0;
    top: 50%;
    zoom: 3;
    height: 0;
    width: 0;
    z-index: -1;
  }

  .MintCards > *:first-child > div > div img {
    -webkit-animation: float 6s ease-in-out infinite;
    animation: float 6s ease-in-out infinite;
  }

  .MintCards > *:last-child > div > div img {
    -webkit-animation: float 6s ease-in-out infinite 3s;
    animation: float 6s ease-in-out infinite 3s;
  }
}

@-webkit-keyframes float {
  25% {
    -webkit-transform: perspective(1000px) rotateY(90deg);
    transform: perspective(1000px) rotateY(90deg);
  }
  50% {
    -webkit-transform: perspective(1000px) rotateY(0);
    transform: perspective(1000px) rotateY(0);
  }
  75% {
    -webkit-transform: perspective(1000px) rotateY(-90deg);
    transform: perspective(1000px) rotateY(-90deg);
  }
  to {
    -webkit-transform: perspective(1000px) rotateY(0deg);
    transform: perspective(1000px) rotateY(0deg);
  }
}
@keyframes float {
  25% {
    -webkit-transform: perspective(1000px) rotateY(90deg);
    transform: perspective(1000px) rotateY(90deg);
  }
  50% {
    -webkit-transform: perspective(1000px) rotateY(0);
    transform: perspective(1000px) rotateY(0);
  }
  75% {
    -webkit-transform: perspective(1000px) rotateY(-90deg);
    transform: perspective(1000px) rotateY(-90deg);
  }
  to {
    -webkit-transform: perspective(1000px) rotateY(0deg);
    transform: perspective(1000px) rotateY(0deg);
  }
}
@-webkit-keyframes float1 {
  0% {
    -webkit-transform: translatey(0);
    transform: translatey(0);
  }
  50% {
    -webkit-transform: translatey(-20px);
    transform: translatey(-20px);
  }
  to {
    -webkit-transform: translatey(0);
    transform: translatey(0);
  }
}
@keyframes float1 {
  0% {
    -webkit-transform: translatey(0);
    transform: translatey(0);
  }
  50% {
    -webkit-transform: translatey(-20px);
    transform: translatey(-20px);
  }
  to {
    -webkit-transform: translatey(0);
    transform: translatey(0);
  }
}

@media screen and (max-width: 749px) {
  .FoundersCards {
    border-radius: 100px;
    -webkit-filter: drop-shadow(10px 10px 200px rgb(255, 0, 255, 0.4))
      drop-shadow(-10px -10px 50px rgb(255, 0, 255, 0.4));
    filter: drop-shadow(10px 10px 200px rgb(255, 0, 255, 0.4))
      drop-shadow(-10px -10px 50px rgb(255, 0, 255, 0.4));
  }

  .FoundersCards > *:first-child > div > div img {
    -webkit-animation: float 6s ease-in-out infinite;
    animation: float 6s ease-in-out infinite;
  }

  .FoundersCards > *:nth-child(2) > div > div img {
    -webkit-animation: float 6s ease-in-out infinite 3s;
    animation: float 6s ease-in-out infinite 3s;
  }

  .FoundersWrapper .container {
    overflow-y: scroll;
    overflow-x: hidden;
  }

  #root
    > div
    > div
    > div.RouteWrapper.FoundersWrapper
    > div.Base
    > div.viewport
    > div.container {
    max-height: 70svh;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }

  .MintCards {
    border-radius: 100px;
    -webkit-filter: drop-shadow(10px 10px 200px rgb(255, 0, 255, 0.4))
      drop-shadow(-10px -10px 50px rgb(255, 0, 255, 0.4));
    filter: drop-shadow(10px 10px 200px rgb(255, 0, 255, 0.4))
      drop-shadow(-10px -10px 50px rgb(255, 0, 255, 0.4));
  }

  .MintCards > *:first-child > div > div img {
    -webkit-animation: float 6s ease-in-out infinite;
    animation: float 6s ease-in-out infinite;
  }

  .MintCards > *:nth-child(2) > div > div img {
    -webkit-animation: float 6s ease-in-out infinite 3s;
    animation: float 6s ease-in-out infinite;
  }
}

@media screen and (max-width: 749px) {
  .FoundersWrapper .container::-webkit-scrollbar {
    display: initial;
    width: 0.8rem;
    opacity: 0.2;
  }

  .FoundersWrapper .container::-webkit-scrollbar-thumb {
    border-radius: 100rem;
    height: 10px;
    display: block;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0.2;
  }

  .FoundersWrapper .container::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.25);
    opacity: 0.2;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}

@media screen and (max-width: 749px) {
  .Base .viewport .container {
    max-width: 90vw;
    top: 50%;
  }

  .Base .viewport .mintContainer {
    max-width: 90vw;
    top: 40%;
  }

  .FoundersCards {
    row-gap: 2rem;
  }

  .MintCards {
    row-gap: 2rem;
  }
}

/* CONTENT */

.Base {
  position: relative;
  z-index: 9;
  height: 100vh;
}

.VisionWrapper .Base {
  position: absolute;
  z-index: 9;
  height: 100vh;
  top: 0;
  width: 100vw;
}

.container {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  top: 40%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 800px;
  z-index: 4;
  max-height: 60vh;
  padding: 0.5rem 2rem 0rem;
  border: 4px solid white;
  -webkit-box-shadow: 0 6px 8px rgb(10 10 10 / 17%);
  box-shadow: 0 6px 8px rgb(10 10 10 / 17%);
  border-radius: 25px;
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(5px);
  -webkit-transition: -webkit-transform 900ms ease-in-out;
  transition: -webkit-transform 900ms ease-in-out;
  -o-transition: transform 900ms ease-in-out;
  transition: transform 900ms ease-in-out;
  transition: transform 900ms ease-in-out, -webkit-transform 900ms ease-in-out;
  -webkit-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: top left;
  color: #fff;
  text-shadow: 1px 1px 3px rgb(0 0 0 / 30%);
}

.map-container {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  top: 40%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 800px;
  z-index: 4;
  max-height: 60vh;
  padding: 0.5rem 2rem 0rem;
  border-radius: 25px;
  -webkit-transition: -webkit-transform 900ms ease-in-out;
  transition: -webkit-transform 900ms ease-in-out;
  -o-transition: transform 900ms ease-in-out;
  transition: transform 900ms ease-in-out;
  transition: transform 900ms ease-in-out, -webkit-transform 900ms ease-in-out;
  -webkit-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: top left;
  color: #fff;
  text-shadow: 1px 1px 3px rgb(0 0 0 / 30%);
}

.map-container h3 {
  font-family: Source Sans\ 3;
  font-size: 2rem;
  letter-spacing: -.05rem;
  text-transform: uppercase;
  transform: scaleY(1.5);
}

@media screen and (max-width: 749px) {
  .Base .viewport .container {
    max-width: 90vw;
    max-height: 60vh;
  }
}

.container h3 {
  -webkit-transform: scale(1, 1.5);
  -ms-transform: scale(1, 1.5);
  transform: scale(1, 1.5);
  font-family: "Source Sans 3";
  font-size: 2rem;
  text-transform: uppercase;
  letter-spacing: -0.05rem;
}

button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  font-family: Helvetica, ui-rounded;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#3898ff),
    to(#7a70ff)
  );
  background-image: -o-linear-gradient(left, #3898ff, #7a70ff);
  background-image: linear-gradient(to right, #3898ff, #7a70ff);
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  line-height: 1;
  font-size: 16px;
  font-weight: 700;
  height: 35px;
  padding: 0 24px;
  border-radius: 9999px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: white;
  -webkit-transition: all ease 100ms;
  -o-transition: all ease 100ms;
  transition: all ease 100ms;
  text-shadow: 2px 2px 2px #2c21dd;
  -webkit-box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.65);
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.65);
}

.button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  font-family: Helvetica, ui-rounded;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#3898ff),
    to(#7a70ff)
  );
  background-image: -o-linear-gradient(left, #3898ff, #7a70ff);
  background-image: linear-gradient(to right, #3898ff, #7a70ff);
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  line-height: 1;
  font-size: 16px;
  font-weight: 700;
  min-width: 150px;
  height: 35px;
  padding: 0 24px;
  border-radius: 9999px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: white;
  -webkit-transition: all ease 100ms;
  -o-transition: all ease 100ms;
  transition: all ease 100ms;
  text-shadow: 2px 2px 2px #2c21dd;
  -webkit-box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.65);
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.65);
}

@media screen and (min-width: 749px) {
  .button.lg.mint,
  .MainConnect button {
    line-height: 1;
    font-size: 3.5rem;
    text-transform: uppercase;
    font-weight: 700;
    height: 35px;
    padding: 2.5rem 4rem;
    border-radius: 9999px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: white;
    -webkit-transition: all ease 100ms;
    -o-transition: all ease 100ms;
    transition: all ease 100ms;
    text-shadow: 2px 2px 2px #2c21dd;
    -webkit-transform: scale(1, 1.5);
    -ms-transform: scale(1, 1.5);
    transform: scale(1, 1.5);
    font-family: "Source Sans 3";
    letter-spacing: -0.05rem;
    white-space: nowrap;
    text-align: center;
    top: 3rem;
    position: relative;
  }
}

.NFTContainer {
  display: none;
}

.NFTContainer.success {
  display: block;
}

button:not(:disabled):hover {
  -webkit-transform: scale(1.04);
  -ms-transform: scale(1.04);
  transform: scale(1.04);
}

button[data-mint-loading="true"] {
  background: rgba(22, 25, 31, 0.24);
  color: white;
}

button[data-mint-started="true"] {
  position: relative;
}

button[data-mint-started="true"]::after {
  -webkit-animation-name: pulse;
  animation-name: pulse;
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  border-radius: inherit;
  bottom: 0;
  content: " ";
  left: 0;
  position: absolute;
  line-height: 3.4rem;
  right: 0;
  top: 0;
}

button[data-mint-done="true"] {
  opacity: 0;
  -webkit-transform: scale(0.85);
  -ms-transform: scale(0.85);
  transform: scale(0.85);
}

[aria-labelledby="rk_connect_title"] > div:nth-child(2),
[aria-labelledby="rk_account_modal_title"] > div:nth-child(2) {
  background-color: hsla(0, 0%, 78.4%, 0.15);
  -webkit-box-shadow: 0 6px 8px rgb(10 10 10 / 17%);
  box-shadow: 0 6px 8px rgb(10 10 10 / 17%);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 1rem;
  border: 1px solid hsla(0, 0%, 100%, 0.18);
  color: #fff;
  text-shadow: 1px 1px 3px rgb(0 0 0 / 30%);
}

/* site map */

.siteMap {
  padding: 0 1rem;
  position: absolute;
  z-index: 12;
  top: 8rem;
  /*        top: 1.5rem;*/
  width: 100vw;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-transform: scale(1, 1.5);
  -ms-transform: scale(1, 1.5);
  transform: scale(1, 1.5);
  font-family: "Source Sans 3";
  font-size: 1.65rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: -0.05rem;
  text-shadow: 1px 1px 2px black;
}

.siteMap a {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#3898ff),
    to(#7a70ff)
  );
  background-image: -o-linear-gradient(left, #3898ff, #7a70ff);
  background-image: linear-gradient(to right, #3898ff, #7a70ff);
  padding: 0 1rem;
  border-radius: 2rem;
}

@media screen and (min-width: 750px) {
  .siteMap {
    display: none;
  }
}

/* MINTING______################################# */

/* SVG STATES WHILE MINT */

.thisStars.minting > #Stars {
  opacity: 0;
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-transform-origin: top center;
  -ms-transform-origin: top center;
  transform-origin: top center;
}

.thisMoon.minting {
  opacity: 0;
}

.Base.minting,
.Button.minting,
.Mint.minting {
  opacity: 1;
}

.container.minting {
  -webkit-transform: scale(1.05) translate(-50%, -50%);
  -ms-transform: scale(1.05) translate(-50%, -50%);
  transform: scale(1.05) translate(-50%, -50%);
}

.minting .bg-Ice {
  opacity: 0;
  top: 40vh;
}

/*  MINT ANIMATION */

.psych {
  opacity: 0;
  min-height: 65vh;
  position: absolute;
  min-width: 100vw;
  z-index: 1;
  -webkit-transition: opacity 900ms linear, top 400ms linear,
    -webkit-transform 400ms linear;
  transition: opacity 900ms linear, top 400ms linear,
    -webkit-transform 400ms linear;
  -o-transition: opacity 900ms linear, transform 400ms linear, top 400ms linear;
  transition: opacity 900ms linear, transform 400ms linear, top 400ms linear;
  transition: opacity 900ms linear, transform 400ms linear, top 400ms linear,
    -webkit-transform 400ms linear;
  will-change: transform, opacity;
}

.psych.minting {
  top: 0;
  opacity: 1;
}

@media screen and (max-width: 749px) {
  .psych {
    left: -50vw;
    width: 200vw;
    left: -50vw;
    top: 0;
  }

  .psych.minting {
    opacity: 1;
  }

  button, .button {
    min-width: 175px;
    text-align: center;
    display: inline;
  }
}

.overlayCard {
  position: absolute;
  bottom: 0.5rem;
  width: 100%;
  padding: 0 1rem;
}

.NFTContainer {
  -webkit-transition: opacity 200ms linear;
  -o-transition: opacity 200ms linear;
  transition: opacity 200ms linear;
}

.NFTContainer.minting {
  opacity: 0;
}

.NFTContainer.success {
  position: absolute;
  opacity: 1;
  right: 0;
  top: -100vh;
  -webkit-animation-duration: 15s;
  animation-duration: 15s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-name: likeJesus;
  animation-name: likeJesus;
  border-radius: 100px;
  -webkit-filter: drop-shadow(10px 10px 200px #ff00ff)
    drop-shadow(-10px -10px 50px #ff00ff);
  filter: drop-shadow(10px 10px 200px #ff00ff)
    drop-shadow(-10px -10px 50px #ff00ff);
}

.NFTContainer.success {
  color: #fff;
}

.NFTContainer.success > div {
  display: block;

  border: 2px solid;
  border-top: 0;
  margin-top: 1rem;
  z-index: 3;
  border-radius: 150px;
}

.NFTContainer.success > div::before,
.NFTContainer.success > div::after {
  content: "";
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: absolute;
}

.NFTContainer.success > div::before {
  width: 2px;
  height: 5px;
  top: calc(100% + 1px);
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  background-color: #fff;
}

.NFTContainer.success > div::after {
  border: 2px solid;
  width: 8px;
  height: 18px;
  left: 50%;
  top: -10px;
  border-radius: 4px;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

.NFTContainer.success > div:after {
  width: 250px;
  height: 250px;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 100%;
  z-index: 1;
  -webkit-box-shadow: 10px -55px 30px 15px #823ca6, 24px -10px 47px 10px #aab3d2,
    -21px -25px 97px 10px #5acee3, 51px 5px 17px 10px #1b7d8f,
    3px 2px 77px 10px #f30bf5;
  box-shadow: 10px -55px 30px 15px #823ca6, 24px -10px 47px 10px #aab3d2,
    -21px -25px 97px 10px #5acee3, 51px 5px 17px 10px #1b7d8f,
    3px 2px 77px 10px #f30bf5;
  -webkit-animation: shadow-rotate 1.5s linear infinite;
  animation: shadow-rotate 1.5s linear infinite;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
}

@-webkit-keyframes circle-size {
  from {
    width: 200px;
    height: 200px;
  }
  to {
    width: 250px;
    height: 250px;
  }
}

@keyframes circle-size {
  from {
    width: 200px;
    height: 200px;
  }
  to {
    width: 250px;
    height: 250px;
  }
}
@-webkit-keyframes shadow-rotate {
  from {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
@keyframes shadow-rotate {
  from {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@-webkit-keyframes likeJesus {
  0% {
    top: -60vh;
  }
  100% {
    top: 0vh;
  }
}
@keyframes likeJesus {
  0% {
    top: -60vh;
  }
  100% {
    top: 0vh;
  }
}

@-webkit-keyframes likeJesus2 {
  0% {
    top: -140vh;
  }
  99% {
    top: 0vh;
  }
  100% {
    position: relative;
  }
}
@keyframes likeJesus2 {
  0% {
    top: -140vh;
  }
  99% {
    top: 0vh;
  }
  100% {
    position: relative;
  }
}

.Mint.container.idle,
.Mint.container.minting {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
  background-color: transparent;
  background-image: none;
  text-align: center;
  -webkit-backdrop-filter: blur(0px);
  backdrop-filter: blur(0px);
}

.Mint.container.idle button.idle,
.Mint.container.minting button.idle,
.Mint.container.idle p,
.Mint.container.minting p {
  margin-left: auto;
  margin-right: auto;
}

.Mint.container.idle h1,
.Mint.container.minting h1,
.Mint.container.idle p,
.Mint.container.minting p {
  display: none;
}

/* WALLET_______################################# */

button.iekbcc0.iekbcc9.ju367vfx.ju367v11.ju367v90 {
  height: 100px;
}

body
  > div:nth-child(3)
  > div
  > div
  > div._9pm4ki5.ju367va.ju367v10.ju367v8g
  > div
  > div
  > div
  > div
  > div:nth-child(2)
  > div
  > div.iekbcc0.ju367vb6.ju367va.ju367v6f.ju367v7z._1am14410
  > div {
  overflow-y: hidden;
  overflow-x: scroll;
}

body
  > div:nth-child(3)
  > div
  > div
  > div._9pm4ki5.ju367va.ju367v10.ju367v8g
  > div
  > div
  > div
  > div
  > div:nth-child(2)
  > div
  > div.iekbcc0.ju367vb6.ju367va.ju367v6f.ju367v7z._1am14410
  > div
  > div:nth-child(1)
  > div
  > button
  > div
  > div.iekbcc0.ju367va.ju367v10.ju367v2n
  > div {
  display: none;
}

body
  > div:nth-child(3)
  > div
  > div
  > div._9pm4ki5.ju367va.ju367v10.ju367v8g
  > div
  > div
  > div
  > div
  > div:nth-child(2)
  > div
  > div.iekbcc0.ju367v70.ju367v7l.ju367v89
  > div
  > button {
  height: 45px;
}

body
  > div:nth-child(3)
  > div
  > div
  > div._9pm4ki5.ju367va.ju367v10.ju367v8g
  > div
  > div
  > div
  > div
  > div:nth-child(2)
  > div
  > div.iekbcc0.ju367vb6.ju367va.ju367v6f.ju367v7z._1am14410
  > div
  > div
  > div
  > button
  > div
  > div.iekbcc0.ju367va.ju367v10.ju367v2n
  > h2
  span {
  white-space: nowrap;
}

#eGWSNMe473i1 {
  z-index: 9;
}

[data-rk] .ju367v93 {
  background: transparent !important;
}

[data-rk] ._9pm4ki3 {
  background-color: rgba(0, 0, 0, 0.8) !important;
}

/* on hover */

.glow-on-hover {
  display: block;
  width: 220px;
  height: 50px;
  border: none;
  outline: none;
  color: #fff;
  background: #111;
  cursor: pointer;
  position: relative;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
}

.glow-on-hover:before {
  content: "";
  background: -o-linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  -webkit-filter: blur(5px);
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  -webkit-animation: glowing 20s linear infinite;
  animation: glowing 20s linear infinite;
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

.glow-on-hover:active:after {
  background: transparent;
}

.glow-on-hover:hover:before {
  opacity: 1;
}

.glow-on-hover:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 10px;
}

@-webkit-keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.overlayCard .button {
  margin-bottom: 1.8rem;
  border-radius: 7.5px;
}

/* root */

:root {
  --base-color: #ffffff;
  --accent-color-1: #8f2384;
  --accent-color-2: #213295;
  --accent-color-3: #9ba5ff;
}

.bg {
  -webkit-transition: background-color 1200ms linear;
  -o-transition: background-color 1200ms linear;
  transition: background-color 1200ms linear;
  -webkit-transition: all 1s linear;
  -o-transition: all 1s linear;
  transition: all 1s linear;
  -webkit-animation: fadeOutBG 12000ms linear infinite alternate forwards;
  animation: fadeOutBG 12000ms linear infinite alternate forwards;
  z-index: -1;
}

.EnterPage .bg {
  -webkit-transition: background-color 1200ms linear;
  -o-transition: background-color 1200ms linear;
  transition: background-color 1200ms linear;
  -webkit-transition: all 1s linear;
  -o-transition: all 1s linear;
  transition: all 1s linear;
  -webkit-animation: fadeOutBG2 12000ms linear infinite alternate forwards;
  animation: fadeOutBG2 12000ms linear infinite alternate forwards;
}

.MintWrapper .bg {
  -webkit-animation: fadeOutBG 1200ms linear forwards;
  animation: fadeOutBG 1200ms linear forwards;
}

@-webkit-keyframes fadeInBG {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInBG {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeOutBG {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

@keyframes fadeOutBG {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

@-webkit-keyframes fadeOutBG2 {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.9;
  }
}

@keyframes fadeOutBG2 {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.9;
  }
}

@-webkit-keyframes SlowSky {
  0% {
    background-color: var(--accent-color-1);
    opacity: 0;
  }
  50% {
    background-color: var(--accent-color-2);
    opacity: 0;
  }
  100% {
    background-color: var(--accent-color-3);
    opacity: 0;
  }
}

@keyframes SlowSky {
  0% {
    background-color: var(--accent-color-1);
    opacity: 0;
  }
  50% {
    background-color: var(--accent-color-2);
    opacity: 0;
  }
  100% {
    background-color: var(--accent-color-3);
    opacity: 0;
  }
}

.BGAlt {
  opacity: 0.6;
  position: absolute;
  z-index: 2;
  height: 100vh;
  width: 100vw;
  min-height: 100vh;
}

.BGAlt svg {
  min-height: 100vh;
}

#Sun {
  opacity: 1;
  -webkit-transition: opacity 800ms linear;
  -o-transition: opacity 800ms linear;
  transition: opacity 800ms linear;
}

/* NAVIGATION */

.app-nav {
  height: 6rem;
  width: 100vw;
  max-width: 100%;
  margin: 0;
  padding: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: 5rem;
  color: #ffffff;
  position: relative;
  z-index: 12;
  top: 0;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.Home.wrap .app-nav {
  display: none;
}

@media screen and (max-width: 749px) {
  .app-nav {
    width: 100vw;
    border-radius: 0rem;
    padding-bottom: 0rem;
    height: 6rem;
  }

  .Main.Home .app-nav {
    height: 9.5rem;
  }

  .Main.Home .top-connect {
    right: 3rem;
    top: 3.4rem;
  }

  .MobileNav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: absolute;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 20px;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100vw;
    align-items: center;
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(5px);
    border-radius: 0rem;
    color: #fff;
    -webkit-box-shadow: 1px 1px 3px rgb(0 0 0 / 30%);
    box-shadow: 1px 1px 3px rgb(0 0 0 / 30%);
    padding-bottom: 0rem;
    height: 6rem;
    border-bottom: 4px solid #ffffff;
  }

  .title {
    display: none;
  }
}

/* HOME - MOBILE VIEW : ENTER PAGE */

.MobileView {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: -webkit-fill-available;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  max-width: 1140px;
  overflow: scroll;
}

.in {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: -webkit-fill-available;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.in-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: -webkit-fill-available;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  row-gap: 5vh;
}

@media screen and (min-width: 749px) {
  .MobileView {
    display: block;
    height: -webkit-fill-available;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

@supports (-webkit-touch-callout: none) {
  .MobileView {
    height: -webkit-fill-available;
  }
}

.welcome {
  width: 85vw;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media screen and (min-width: 749px) {
  .welcome {
    position: absolute;
    bottom: 0;
    padding: 1rem 0rem 0 2rem;
    background-color: rgba(67, 120, 187, 0.85);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    min-width: 35rem;
  }

  .welcome .rotate {
    position: relative !important;
  }
}

.title-arc {
  text-align: center;
  font-size: 4rem;
  color: white;
}

.s1 {
  position: relative;
  display: inline-block;
  font-family: "Source Sans 3";
  font-weight: bold;
  -webkit-transform: translate(-2rem, 1rem) rotate(-35deg) scale(1, 1.5);
  -ms-transform: translate(-2rem, 1rem) rotate(-35deg) scale(1, 1.5);
  transform: translate(-2rem, 1rem) rotate(-35deg) scale(1, 1.5);
  text-shadow: 1px 1px 2px black;
}

.s2 {
  position: relative;
  display: inline-block;
  font-family: "Source Sans 3";
  font-weight: bold;
  -webkit-transform: translate(-0.75rem, -0.5rem) rotate(-10deg) scale(1, 1.5);
  -ms-transform: translate(-0.75rem, -0.5rem) rotate(-10deg) scale(1, 1.5);
  transform: translate(-0.75rem, -0.5rem) rotate(-10deg) scale(1, 1.5);
  text-shadow: 1px 1px 2px black;
}

.s3 {
  position: relative;
  display: inline-block;
  font-family: "Source Sans 3";
  font-weight: bold;
  -webkit-transform: translate(0.75rem, -0.5rem) rotate(10deg) scale(1, 1.5);
  -ms-transform: translate(0.75rem, -0.5rem) rotate(10deg) scale(1, 1.5);
  transform: translate(0.75rem, -0.5rem) rotate(10deg) scale(1, 1.5);
  text-shadow: 1px 1px 2px black;
}

.s4 {
  position: relative;
  display: inline-block;
  font-family: "Source Sans 3";
  font-weight: bold;
  -webkit-transform: translate(2rem, 1rem) rotate(35deg) scale(1, 1.5);
  -ms-transform: translate(2rem, 1rem) rotate(35deg) scale(1, 1.5);
  transform: translate(2rem, 1rem) rotate(35deg) scale(1, 1.5);
  text-shadow: 1px 1px 2px black;
}

.welcome .col-2 {
  position: relative;
}

.welcome .rotate {
  position: absolute;
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(-90deg);
  -ms-transform: translateX(-50%) translateY(-50%) rotate(-90deg);
  transform: translateX(-50%) translateY(-50%) rotate(-90deg);
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  top: 50%;
  left: 50%;
  text-shadow: 1px 1px 2px black;
}

.common-title {
  line-height: 3.65rem;
}

.welcome + div p {
  font-size: 1.65rem;
  line-height: 2.85rem;
}

.welcome .copyright {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: -webkit-fill-available !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

/* desktop */

.app-nav h1 {
  margin: 0;
  line-height: 28px;
  -webkit-transform: scale(1, 1.5);
  -ms-transform: scale(1, 1.5);
  transform: scale(1, 1.5);
  margin: 0;
  font-family: "Source Sans 3";
  font-size: 2rem;
  text-transform: uppercase;
  letter-spacing: -0.05rem;
  white-space: nowrap;
  text-align: center;
}

.navigation {
  -webkit-column-gap: 2rem;
  -moz-column-gap: 2rem;
  column-gap: 2rem;
  list-style: none;
  overflow: visible;
  margin: 0;
}

.header__menu {
  position: absolute;
  top: 55px;
  padding: 0rem;
  height: calc(100vh - 55px);
  z-index: 1;
  left: -0.2rem;
  text-align: left;
  -webkit-transition: -webkit-transform 0.2s linear;
  transition: -webkit-transform 0.2s linear;
  -o-transition: transform 0.2s linear;
  transition: transform 0.2s linear;
  transition: transform 0.2s linear, -webkit-transform 0.2s linear;
  width: 100vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(hsla(211, 100%, 61%, 0.85)),
    to(hsla(244, 100%, 72%, 0.85))
  );
  background-image: -o-linear-gradient(
    left,
    hsla(211, 100%, 61%, 0.85),
    hsla(244, 100%, 72%, 0.85)
  );
  background-image: linear-gradient(
    to right,
    hsla(211, 100%, 61%, 0.85),
    hsla(244, 100%, 72%, 0.85)
  );
  opacity: 0.95;
  border-radius: 2rem;
  background-color: hsla(0, 0%, 78.4%, 0.15);
  -webkit-box-shadow: 0 6px 8px rgb(10 10 10 / 17%);
  box-shadow: 0 6px 8px rgb(10 10 10 / 17%);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  border-radius: 1rem;
  color: #fff;
  text-shadow: 1px 1px 3px rgb(0 0 0 / 30%);
  border: 4px solid white;
}

.header__menu.slide {
  -webkit-transform: translateY(110vh);
  -ms-transform: translateY(110vh);
  transform: translateY(110vh);
}

.header__menu ul {
  padding: 0;
}

.header__menu li {
  margin-bottom: 1rem;
}

/* new bg */

.HomeWrapper .title {
  opacity: 0;
}

.BG.transition {
  position: absolute;
  z-index: 2;
}

.BG.transition > * {
  -webkit-transform: scale(0.65) translateX(-1000px);
  -ms-transform: scale(0.65) translateX(-1000px);
  transform: scale(0.65) translateX(-1000px);
}

.HomeWrapper .BG.transition > * {
  -webkit-transform: scale(0.65) translateX(-1000px) scaleX(-1);
  -ms-transform: scale(0.65) translateX(-1000px) scaleX(-1);
  transform: scale(0.65) translateX(-1000px) scaleX(-1);
}

.AboutWrapper .BG.transition > * {
  -webkit-transform: scale(0.75) translateX(-600px) scaleX(-1);
  -ms-transform: scale(0.75) translateX(-600px) scaleX(-1);
  transform: scale(0.75) translateX(-600px) scaleX(-1);
}

.RoadmapWrapper .BG.transition > * {
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
  transform: scaleX(-1);
}

.BG.vision {
  bottom: unset;
  position: absolute;
  height: 100vh;
  width: 100vw;
}

@media screen and (max-width: 980px) {
  .BG.transition > * {
    -webkit-transform: scale(0.65) translateX(-700vw);
    -ms-transform: scale(0.65) translateX(-700vw);
    transform: scale(0.65) translateX(-700vw);
  }

  .FoundersWrapper .BG.transition > * {
    -webkit-transform: scale(0.3) translateX(-545vw);
    -ms-transform: scale(0.3) translateX(-545vw);
    transform: scale(0.3) translateX(-545vw);
    top: 0;
    position: relative;
  }

  .HomeWrapper .BG.transition > * {
    -webkit-transform: scale(0.65) translateX(-1000px) scaleX(-1);
    -ms-transform: scale(0.65) translateX(-1000px) scaleX(-1);
    transform: scale(0.65) translateX(-1000px) scaleX(-1);
  }
}

.Sc-Media {
  height: auto;
}

.Base .Bottom .BG.main {
  position: absolute;
  height: 100vh;
  width: 100vw;
}

.Sc-Main {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: auto;
  height: 100vh;
}

@media screen and (max-width: 750px) {
  .Sc-Main {
    min-width: unset !important;
    min-height: unset !important;
    max-height: 100vh !important;
  }
}

@media screen and (min-width: 749px) {
  .Sc-Main {
    max-width: 120vw;
    max-height: 120vh;
    width: auto;
    height: auto;
    min-height: 100vh;
    min-width: 100vw;
    -o-object-fit: cover;
    object-fit: cover;
  }
}

.Sc-Media.Sc-Mint {
  min-width: 100vw;
  -o-object-fit: cover;
  object-fit: cover;
  min-height: 100vh;
  height: 100%;
  width: 100%;
}

.Sc-Timeline {
  max-width: 70vw;
}

.Sc-Founders {
  position: absolute;
  max-width: 150vw;
  left: -45vw;
  bottom: -10vh;
}
@media screen and (min-width: 749px) {
  .Sc-Founders {
    max-width: 120vw;
    left: -5vw;
    bottom: 0;
  }
}

.Sc-Vision {
  position: absolute;
  max-width: 170vw;
  left: -20vw;
  bottom: 0;
  z-index: 9;
}
@media screen and (min-width: 749px) {
  .Sc-Vision {
    left: unset;
    position: absolute;
    max-width: 100vw;
    bottom: 0;
    z-index: 9;
  }
}

.Sc-Tokenomics {
  max-width: unset;
}

@media screen and (max-width: 749px) {
  .BG.main > *:not(.Sc-Main) {
    -webkit-transform: translate(5vw, 0vh) rotate(0deg) scale(1.55);
    -ms-transform: translate(5vw, 0vh) rotate(0deg) scale(1.55);
    transform: translate(5vw, 0vh) rotate(0deg) scale(1.55);
  }

  .BG.main > *:not(.Sc-Main):first-child {
    -webkit-transform: translate(10vw, 0vh) rotate(0deg) scale(1.85);
    -ms-transform: translate(10vw, 0vh) rotate(0deg) scale(1.85);
    transform: translate(10vw, 0vh) rotate(0deg) scale(1.85);
  }

  .Sc-Media.Sc-Mint {
    min-width: 100vw;
    -o-object-fit: cover;
    object-fit: cover;
    min-height: 100vh;
    height: 100%;
    width: 100%;
  }

  .Sc-Timeline {
    max-width: 150vw;
    left: -35vw;
    position: relative;
  }

  .Sc-About {
    position: relative;
    max-width: 135vw;
    left: -15vw;
  }

  .Sc-Tokenomics {
    bottom: 0;
    position: absolute;
    max-height: 100vh;
    min-height: 100vh;
    left: 0%;
    -webkit-transform: translateX(-10%);
    -ms-transform: translateX(-10%);
    transform: translateX(-10%);
  }
}

/* DESKTOP MAP BUTTONS */

.background.map {
  background-image: url("../../public/bg.jpg");
  background-size: cover;
  background-position: center;
  position: absolute;
  z-index: 3;
  height: 100vh;
  width: 100vw;
  -webkit-transition: all 800ms ease-in-out;
  -o-transition: all 800ms ease-in-out;
  transition: all 800ms ease-in-out;
}

a.link-to {
  position: absolute;
  z-index: 9;
  opacity: 0.4;
  text-align: center;
  -webkit-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  transition: all 300ms linear;
}

a.link-to:hover {
  opacity: 1;
  scale: 1.4;
}

.eRoad a.link-to.link-roadmap {
  opacity: 1;
  -webkit-transform: scale(2.5, 2.5);
  -ms-transform: scale(2.5, 2.5);
  transform: scale(2.5, 2.5);
  -webkit-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  transition: all 300ms linear;
}

.eAbout a.link-to.link-about {
  opacity: 1;
  -webkit-transform: scale(2.5, 2.5);
  -ms-transform: scale(2.5, 2.5);
  transform: scale(2.5, 2.5);
  -webkit-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  transition: all 300ms linear;
}

.eTokenomics a.link-to.link-tokenomics {
  opacity: 1;
  -webkit-transform: scale(2.5, 2.5);
  -ms-transform: scale(2.5, 2.5);
  transform: scale(2.5, 2.5);
  -webkit-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  transition: all 300ms linear;
}

.eFounders a.link-to.link-founders {
  opacity: 1;
  -webkit-transform: scale(2.5, 2.5);
  -ms-transform: scale(2.5, 2.5);
  transform: scale(2.5, 2.5);
  -webkit-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  transition: all 300ms linear;
}

.eVision a.link-to.link-vision {
  opacity: 1;
  left: 50% !important;
  width: 35px;
  position: absolute;
  -webkit-transform: scale(2.5, 2.5) translateX(-50%) !important;
  -ms-transform: scale(2.5, 2.5) translateX(-50%) !important;
  transform: scale(2.5, 2.5) translateX(-50%) !important;
  -webkit-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  transition: all 300ms linear;
}

@media screen and (max-width: 749px) {
  a.link-to {
    display: none;
  }
}

.link-roadmap {
  right: 0;
  bottom: 0;
  height: 20vh;
  width: 20vw;
  line-height: 20vh;
}

.link-roadmap:hover ~ .background.map,
.eRoad .background.map {
  -webkit-transform: scale(1.25);
  -ms-transform: scale(1.25);
  transform: scale(1.25);
  -webkit-transform-origin: bottom right;
  -ms-transform-origin: bottom right;
  transform-origin: bottom right;
}

.link-about {
  left: 0;
  bottom: 0;
  height: 20vh;
  width: 20vw;
  line-height: 20vh;
}

.link-about:hover ~ .background.map,
.eAbout .background.map {
  -webkit-transform: scale(1.25);
  -ms-transform: scale(1.25);
  transform: scale(1.25);
  -webkit-transform-origin: bottom left;
  -ms-transform-origin: bottom left;
  transform-origin: bottom left;
}

.link-founders {
  left: 0;
  top: 0;
  height: 20vh;
  width: 20vw;
  line-height: 20vh;
}

.link-founders:hover ~ .background.map,
.eFounders .background.map {
  -webkit-transform: scale(1.25);
  -ms-transform: scale(1.25);
  transform: scale(1.25);
  -webkit-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: top left;
}

.link-tokenomics {
  right: 0;
  top: 0;
  height: 20vh;
  width: 20vw;
  line-height: 20vh;
}

.link-tokenomics:hover ~ .background.map,
.eTokenomics .background.map {
  -webkit-transform: scale(1.25);
  -ms-transform: scale(1.25);
  transform: scale(1.25);
  -webkit-transform-origin: top right;
  -ms-transform-origin: top right;
  transform-origin: top right;
}

.link-vision {
  bottom: 50%;
  height: 20vh;
  line-height: 20vh;
  left: 50%;
  width: 20vw;
  -webkit-transform: scaleY(1.5) translateX(-50%) !important;
  -ms-transform: scaleY(1.5) translateX(-50%) !important;
  transform: scaleY(1.5) translateX(-50%) !important;
}

.link-vision:hover ~ .background.map,
.eVision .background.map {
  -webkit-transform: scale(1.25);
  -ms-transform: scale(1.25);
  transform: scale(1.25);
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
}

.before {
  overflow: visible;
}

.gallery img {
  max-width: 300px;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  padding: 0.5rem;
}

.BuddhaBoysWrapper .container {
  top: 138px !important;
  max-height: 75vh;
  -webkit-tranform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.stripe {
  margin-left: 12px;
}

.stripe-button {
  margin-top: 40px;
}

.stripe-btn {
  margin: 40px 0px;
}

@media screen and (max-width: 749px) {
  .stripe-btn {
    margin: 16px 0;
  }
}

.demoNFT {
  max-width: 300px;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
}

[data-rk] ._12cbo8i7,
[data-rk] ._12cbo8i7::after {
  height: 43px !important;
}

[data-rk]
  .iekbcc0.ju367va.ju367v10.ju367v6p
  > .iekbcc0.ju367vbl.ju367va.ju367v10.ju367v6c.ju367v89
  + .ju367v10,
[data-rk]
  .iekbcc0.ju367va.ju367v10.ju367v6p
  > .iekbcc0.ju367vbl.ju367va.ju367v10.ju367v6c.ju367v89
  + .ju367v10
  > * {
  height: 350px;
}

[data-rk]
  .iekbcc0.ju367va.ju367v10.ju367v6p
  > .iekbcc0.ju367vbl.ju367va.ju367v10.ju367v6c.ju367v89
  + .ju367v10
  > *
  > *:first-child {
  padding: 3rem 0 2rem;
}

[data-rk] .ju367v7r {
  padding: 1rem !important;
}

/* mobile nav button */

#navButton > .bars {
  zoom: 0.2;
}

#navButton .bars {
  width: 120px;
  height: 70px;
  position: relative;
  -webkit-transition: -webkit-transform 0.3s
    cubic-bezier(0.694, 0.048, 0.335, 1);
  transition: -webkit-transform 0.3s cubic-bezier(0.694, 0.048, 0.335, 1);
  -o-transition: transform 0.3s cubic-bezier(0.694, 0.048, 0.335, 1);
  transition: transform 0.3s cubic-bezier(0.694, 0.048, 0.335, 1);
  transition: transform 0.3s cubic-bezier(0.694, 0.048, 0.335, 1),
    -webkit-transform 0.3s cubic-bezier(0.694, 0.048, 0.335, 1);
  transition: transform 0.3s cubic-bezier(0.694, 0.048, 0.335, 1),
    -webkit-transform 0.3s cubic-bezier(0.694, 0.048, 0.335, 1);
}

#navButton .bars span {
  display: block;
  width: 100%;
  height: 10px;
  background-color: white;
  margin-top: 20px;
  box-shadow: 6.5px 6.5px 2px black;
}

#navButton .bars span:first-child {
  margin-top: 0;
  -webkit-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transition: -webkit-transform 0.3s
    cubic-bezier(0.694, 0.048, 0.335, 1);
  transition: -webkit-transform 0.3s cubic-bezier(0.694, 0.048, 0.335, 1);
  -o-transition: transform 0.3s cubic-bezier(0.694, 0.048, 0.335, 1);
  transition: transform 0.3s cubic-bezier(0.694, 0.048, 0.335, 1);
  transition: transform 0.3s cubic-bezier(0.694, 0.048, 0.335, 1),
    -webkit-transform 0.3s cubic-bezier(0.694, 0.048, 0.335, 1);
  transition: transform 0.3s cubic-bezier(0.694, 0.048, 0.335, 1),
    -webkit-transform 0.3s cubic-bezier(0.694, 0.048, 0.335, 1);
  -webkit-transition-delay: 0.4s;
  -o-transition-delay: 0.4s;
  transition-delay: 0.4s;
}

#navButton .bars span:nth-child(3) {
  width: 65%;
  -webkit-transition: width 0.2s cubic-bezier(0.694, 0.048, 0.335, 1);
  -o-transition: width 0.2s cubic-bezier(0.694, 0.048, 0.335, 1);
  transition: width 0.2s cubic-bezier(0.694, 0.048, 0.335, 1);
  -webkit-transition-delay: 0.4s;
  -o-transition-delay: 0.4s;
  transition-delay: 0.4s;
}

#navButton .bars .other-bar {
  width: 10px;
  height: 0;
  background-color: white;
  position: absolute;
  top: -23px;
  left: 50%;
  margin-left: -5px;
  -webkit-transition: height 0.4s cubic-bezier(0.694, 0.048, 0.335, 1);
  -o-transition: height 0.4s cubic-bezier(0.694, 0.048, 0.335, 1);
  transition: height 0.4s cubic-bezier(0.694, 0.048, 0.335, 1);
  z-index: 10;
}

#navButton.open .bars {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition-delay: 0.4s;
  -o-transition-delay: 0.4s;
  transition-delay: 0.4s;
}

#navButton.open .bars span:first-child {
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: -webkit-transform 0.2s
    cubic-bezier(0.694, 0.048, 0.335, 1);
  transition: -webkit-transform 0.2s cubic-bezier(0.694, 0.048, 0.335, 1);
  -o-transition: transform 0.2s cubic-bezier(0.694, 0.048, 0.335, 1);
  transition: transform 0.2s cubic-bezier(0.694, 0.048, 0.335, 1);
  transition: transform 0.2s cubic-bezier(0.694, 0.048, 0.335, 1),
    -webkit-transform 0.2s cubic-bezier(0.694, 0.048, 0.335, 1);
  transition: transform 0.2s cubic-bezier(0.694, 0.048, 0.335, 1),
    -webkit-transform 0.2s cubic-bezier(0.694, 0.048, 0.335, 1);
}

#navButton.open .bars span:nth-child(3) {
  width: 0;
  -webkit-transition: width 0.2s cubic-bezier(0.694, 0.048, 0.335, 1);
  -o-transition: width 0.2s cubic-bezier(0.694, 0.048, 0.335, 1);
  transition: width 0.2s cubic-bezier(0.694, 0.048, 0.335, 1);
}

#navButton.open .bars .other-bar {
  height: 120px;
  -webkit-transition: height 0.2s cubic-bezier(0.694, 0.048, 0.335, 1);
  -o-transition: height 0.2s cubic-bezier(0.694, 0.048, 0.335, 1);
  transition: height 0.2s cubic-bezier(0.694, 0.048, 0.335, 1);
  -webkit-transition-delay: 0.3s;
  -o-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.gallery {
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  -ms-grid-columns: (1fr) [3];
}

.dropdown {
  position: relative;
}

.dropdown select {
  /* appearance: none; */
  background-image: linear-gradient(to right, #3898ff, #7a70ff);
  border-radius: 6px;
  padding: 10px 30px 10px 15px;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  cursor: pointer;
  min-width: 250px;

  outline: none;
  text-align: center;
  text-transform: uppercase;
}

@media screen and (max-width: 749px) {
  .dropdown select {
    width: 100% !important;
    min-width: unset !important;
  }
}

.dropdown select option {
  background-color: #fff;
  color: #000;
}

@media screen and (max-width: 749px) {
  .gallery {
    grid-template-columns: repeat(1, 1fr);
    -ms-grid-columns: (1fr) [1];
  }
  .gallery img {
    margin-bottom: 3rem;
  }
  .stripe {
    margin-left: 0;
  }
  .stripe-button {
    margin-top: 14px;
  }
  .dropdown {
    padding: 10px;
  }

  .demoNFT {
    height: 250px !important;
  }
}
