.help-button {
  position: absolute;
  z-index: 10;
  position: absolute;
  left: 50%;
  bottom: 0rem;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

@media screen and (max-width: 749px) {
  .help-button {
    bottom: 12rem;
  }
  .access_utility {
    margin-bottom: 18px;
  }
}

.help-button:hover {
  transform: translateX(-50%) !important;
}
/* Modal */

.overlayModal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 98;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.65);
}

.modal {
  position: relative;
  z-index: 99;
  width: 95%;
  max-width: 60rem;
  max-height: 90%;
  margin: 0 auto;
  overflow: scroll;
  box-shadow: 0 6px 8px rgb(10 10 10 / 17%);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 1rem;
  border: 1px solid hsla(0, 0%, 100%, 0.18);
  color: #fff;
  text-shadow: 1px 1px 3px rgb(0 0 0 / 30%);
  border: 4px solid white !important;
}

.modal-close {
  position: absolute;
  top: 0rem;
  right: 0;
  padding: 10px 20px;
  border: 0;
  -webkit-appearance: none;
  background: none;
  color: white;
  cursor: pointer;
  font-size: 1.5rem;
  z-index: 66;
  box-shadow: 0 6px 8px rgb(10 10 10 / 0%);
}

@media screen and (max-width: 768px) {
  .modal {
    max-height: 80%;
  }

  .modal-close {
    top: 0rem;
    right: 8px;
    padding: 10px;
  }
}

.modal-body {
  padding: 1.25rem 1.5rem;
  border-radius: 4px;
  color: #fff;
  border-radius: 14px;
  font-size: 15px;
  font-weight: 500;

  background-color: hsla(0, 0%, 78.4%, 0.15);
  box-shadow: 0 6px 8px rgb(10 10 10 / 17%);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 1rem;
  border: 1px solid hsla(0, 0%, 100%, 0.18);
  color: #fff;
  text-shadow: 1px 1px 3px rgb(0 0 0 / 30%);
}

.modal-transition-enter .overlay {
  opacity: 0;
}

.modal-transition-enter-active .overlay {
  opacity: 1;
  transition: opacity 200ms;
}

.modal-transition-exit-active .overlay {
  opacity: 1;
}

.modal-transition-exit-active .overlay {
  opacity: 0;
  transition: opacity 200ms;
}

.modal-transition-enter .modal {
  opacity: 0;
  transform: scale(0.95) translateY(-30px);
}

.modal-transition-enter-active .modal {
  opacity: 1;
  transform: translateX(0) translateY(0);
  transition: opacity 200ms, transform 200ms;
}

.modal-transition-exit .modal {
  opacity: 1;
}

.modal-transition-exit-active .modal {
  opacity: 0;
  transform: scale(0.95) translateY(-30px);
  transition: opacity 200ms, transform 200ms;
}
